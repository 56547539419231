* {
  margin: 0px;
  padding: 0px;
  /* background: grey; */
}

/* *::-moz-selection {
  color: black;
  background: white;
}
*::selection {
  color: black;
  background: white;
} */

/* loader */

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: black; */
  z-index: 0;
  opacity: 0.9;
  transition: 600ms;
  pointer-events: none;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: black;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* nav-bar */

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 8vh;
  background-color: black;
  color: rgb(247, 241, 241);
  text-transform: uppercase;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  letter-spacing: 3px;
  position: fixed;
  width: 100%;
  z-index: 30;
}
nav a {
  text-decoration: none;
  color: rgb(247, 241, 241);
  cursor: pointer;
}
nav i {
  font-size: 50px;
  margin-left: 0 auto;
  padding-left: 85vw;
  color: rgb(247, 241, 241);
}

.nav-links {
  display: flex;
  justify-content: space-around;
  width: 95%;
}

.nav-links li {
  list-style: none;
}

.nav-links input {
  color: rgb(247, 241, 241);
  text-decoration: none;
  letter-spacing: 3px;
}

.nav-projet {
  justify-content: space-around;
}

input {
  visibility: hidden;
}
label {
  cursor: pointer;
}

input[type="radio"]:checked + label {
  color: white;
}

.burger {
  display: none;
  cursor: pointer;
  padding-right: 15px;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: rgb(247, 241, 241);
  margin: 5px;
  transition: all 0.3s ease;
}

.small {
  display: none;
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .nav-links {
    position: absolute;
    right: 0px;
    height: 92vh;
    top: 8vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    font-size: 15px;
  }
  .nav-links li {
    opacity: 0;
  }

  .burger {
    display: block;
  }
  .content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .big {
    display: none;
  }
  .small {
    display: block;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* grid */

.grid {
  margin: 0 auto;
  padding-top: 8vh;
}

.book-index {
  width: 100%;
  max-width: 235px;
  font-size: 0;
  /* height: auto; */
}

.grid-item {
  width: 235px;
  padding: 40px;
  /* transition: 1000ms; */
}
.item-2 {
  width: 200px;
  padding-bottom: 0px;
}

.coucou {
  /* margin-top: 70px; */
}

.grid-item--width3 {
  width: 200px;
}

/* work */

.work {
  padding-top: 10vh;
  font-family: "Open Sans", sans-serif;
  transition: 2000ms;
}

.infos {
  padding: 30px;
  position: fixed;
}

.openbook {
  max-width: 200px;
  width: 100%;
  height: auto;
}

.text-label {
  padding-top: 20px;
  max-width: 270px;
}
.horizontal-label {
  max-width: 500px;
}

.text-label a {
  color: black;
}

.content {
  max-width: 600px;
  width: auto;
  margin: 0 auto;
}

.content2 {
  max-width: 1200px;
  width: auto;
  margin: 0 auto;
  padding: 10px;
}
.noclass {
  padding-top: 20px;
  padding-bottom: 20px;
}
.image-work {
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.safariwork {
  padding-top: 20px;
  padding-bottom: 20px;
}

#video {
  padding-top: 100px;
  width: 95%;
  max-width: 1000px;
  margin: 0 auto;
  transition: 1500ms;
}

#video p {
  font-family: "Open Sans", sans-serif;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  line-height: 1.5;
}

#video p a {
  color: black;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 1170px) {
  .infos {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: center;
    max-width: 600px;
    padding: 0 15px 0 15px;
    margin: 0 auto;
  }
  .text-label {
    padding-left: 10px;
  }
}

p::-moz-selection {
  color: white;
  background: black;
}
p::selection {
  color: white;
  background: black;
}
a::-moz-selection {
  color: white;
  background: black;
}
a::selection {
  color: white;
  background: black;
}

/* contact  */

/* .contact-tab {
	width: 100%;
	height: 100%;
	position: fixed;
	background-image: url("img/simon/me.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	top: -100vh;
	overflow: hidden;
	transition: 0.6s ease;
	color: white;
	z-index: 40;
} */

.contact-tab {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: black;
  top: -100vh;
  overflow: hidden;
  transition: 0.6s ease;
  color: white;
  z-index: 40;
}
.contact-tab i {
  cursor: pointer;
}

.fa-arrow-circle-up {
  color: rgb(247, 241, 241);
  position: absolute;
  bottom: 0;
  left: 85%;
  padding: 40px;
}

.text-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.text-container p {
  font-size: 2rem;
  padding: 20px 30px 20px 30px;
  /* padding-bottom: 80px; */
  font-family: "Open Sans", sans-serif;
}

.text-container p::selection {
  color: black;
  background-color: white;
}
.text-container a::selection {
  color: black;
  background-color: white;
}

.text-container a {
  text-decoration: none;
  color: rgb(247, 241, 241);
}
.img-div {
  height: 100%;
  width: 100%;
}
.img-simon {
  width: auto;
  /* max-width: 800px; */
  height: 90%;
  margin: 30px;
}

.contact-text p {
  font-family: "Open Sans", sans-serif;
  color: rgb(247, 241, 241);
  font-size: 1.4rem !important;
  /* padding: 30px; */
}

.contact-text a {
  text-decoration: underline;
}

.contact-text2 {
  font-size: 3rem !important;
}
.contact-text2 i {
  padding-right: 10px;
  font-size: 3rem !important;
}

.contact-text3 {
  font-size: 0.8rem !important;
}

.contact-text::selection {
  color: black;
  background: white;
}

.info-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  line-height: 1.4;
  overflow-y: scroll;
}

@media screen and (max-width: 768px) {
  .text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow-y: scroll;
  }
  .img-simon {
    display: none;
    /* width: 100%;
		height: auto !important;
		max-width: 90%;
		padding-top: 20px;
		padding-bottom: 10px; */
  }

  .info-text {
    /* padding-top: 80%; */
    height: 100% !important;
  }

  .text-container p {
    font-size: 2rem;
  }

  .contact-tab {
    width: 100vw !important;
    overflow-y: scroll;
  }

  .fa-arrow-circle-up {
    padding: 20px;
    left: 75%;
  }

  .contact-text {
    font-size: 0.8em;
    /* padding-top: 200px; */
  }

  .contact-text p {
    font-size: 1.2rem !important;
  }
}
